import React, { useState, useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import {interceptors} from "axios";

function IngredientSelector() {
    const [ingredients, setIngredients] = useState([]);
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [potions, setPotions] = useState([]);

    useEffect(() => {
        const fetchIngredients = async () => {
            try {
                const response = await fetch("https://api.lexikonn.eu/skyrim/ingredients.php");
                const data = await response.json();
                setIngredients(data);
            } catch (error) {
                console.error("Chyba při načítání ingrediencí:", error);
            }
        };

        fetchIngredients();
    }, []);

    const handleCheckboxChange = (id) => {
        setSelectedIngredients((prev) =>
            prev.includes(id)
                ? prev.filter((ingredientId) => ingredientId !== id)
                : [...prev, id]
        );
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedIngredients.length === 0) {
            alert("Musíš vybrat alespoň jednu ingredienci!");
            return;
        }

        try {
            const response = await fetch("https://api.lexikonn.eu/skyrim/alchemy.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ingredients: selectedIngredients }),
            });

            const data = await response.json();
            if (data.status === "success") {
                setPotions(data.potions);
            } else {
                alert("Chyba při odesílání ingrediencí.");
            }
        } catch (error) {
            console.error("Chyba při komunikaci s API:", error);
            alert("Něco se pokazilo, zkus to prosím znovu.");
        }
    };

    return (
        <div className="container pb-5 mt-5">
            <h1 className="mb-4 text-success InfraRed">Vyber ingredience, které máš u sebe</h1>
            <form className="box" onSubmit={handleSubmit}>
                <div className="row row-cols-1 row-cols-md-4 g-3">
                    {ingredients.map((ingredient) => (
                        <div className="col" key={ingredient.id}>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`ingredient-${ingredient.id}`}
                                    value={ingredient.id}
                                    onChange={() => handleCheckboxChange(ingredient.id)}
                                />
                                <label className="form-check-label" htmlFor={`ingredient-${ingredient.id}`}>
                                    {ingredient.name}
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
                <button type="submit" className="btn btn-success mt-4">
                    Odeslat vybrané ingredience
                </button>
            </form>
            <div className="mt-5 box">
                <h2 className="InfraRed">Možné lektvary</h2>
                {potions.length > 0 ? (
                    <ul>
                        {potions.map((potion, index) => (
                            <li key={index}>
                                <div className="text-success">
                                    <strong>Ingredience:</strong> {potion.ingredients.join(", ")}
                                </div>
                                <div style={{color: "#d16b1e"}}>
                                    <strong>Efekty:</strong> {potion.effects.join(", ")}
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Zatím nejsou žádné výsledky.</p>
                )}
            </div>
        </div>
    );
}

export default IngredientSelector;
