import React from "react";
import IngredientSelector from "./IngredientSelector";
import { Container } from 'react-bootstrap';
import logo from "./img/logo.png";

function App() {
    return (
        <div>
            <center>
                <img className="logo mt-3" src={logo}/>
            </center>
            <Container>
                <IngredientSelector />
            </Container>
        </div>
    );
}

export default App;
